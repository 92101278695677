import { CompanyCustomerEntity, EMPTY_COMPANY_CUSTOMER_ENTITY } from "domain/entity/Company/CompanyCustomerEntity";
import { CustomerEntity } from "domain/entity/Company/CustomerEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo";
import { CustomerAssignmentRepository } from "domain/repository/Company/CustomerAssignmentRepo";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import _ from "lodash";
import { CompanyEnabledSearchCriteria } from "presentation/constant/Company/CompanyEnabledSearchCriteria";
import { CompanySearchCriteria } from "presentation/constant/Company/CompanySearchCriteria";
import { commonCompCustAssignValidationSchema } from "presentation/constant/Company/CompCustAssignValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { CompanyDetailModel } from "presentation/model/Company/CompanyDetailModel";

import { DropdownProps } from "presentation/model/DropdownProps";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CustomerAssignmentVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]   
    customerAssignmentRepo: CustomerAssignmentRepository, 
    exchangeRateRepo: ExchangeRateRepository,
    customerRepo: CustomerRepository,
    searchPreferenceRepo: SearchPreferenceRepository<CompanySearchCriteria,CompanyEnabledSearchCriteria>,
    masterDataRepo: MasterDataRepository
}

export const CustomerAssignmentVM = ({ dispatch, customerAssignmentRepo, exchangeRateRepo, customerRepo, masterDataRepo}: CustomerAssignmentVMProps) => {
    const [companyDetailDispatch] = dispatch;

    const loadDropdownOption = async() => {        
        await masterDataRepo.getMasterDataByKey(MasterDataType.INTER_COMPANY).then(
            interCompanys => {
                const sortInterCompanys = _.orderBy(interCompanys, ["code"]);
                const interCompanyDropdownOptions = sortInterCompanys?.map((interCompany)=>({
                    dropdownLabel: interCompany.code,
                    tagLabel: interCompany.code,
                    value: interCompany.code,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        interCompanyCodeDropdownOptions: [
                            ...interCompanyDropdownOptions]
                    }
                }))
            }
        ) 
        
        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyCodeDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        )

        await customerRepo.getCustomerActive().then(
            customers => {
                const sortCustomers = _.orderBy(customers, ["customerCode"]);
                let customerCodeDropdownOptions: DropdownProps[] = [];                  
                for (let i = 0; i < sortCustomers.length; i++) {
                    if (sortCustomers[i].id) {
                        customerCodeDropdownOptions.push({dropdownLabel: sortCustomers[i].customerCode,
                            tagLabel: sortCustomers[i].id.toString(),
                            value: sortCustomers[i].customerCode});
                    }
                }           
                
                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: customerCodeDropdownOptions,
                    }
                }))
            }
        )
    }

    const onInitDefaultValue = () => {
        companyDetailDispatch(prevState => {
            const customerAssign = prevState.customerAssignmentState.currentCompanyCust;
            const interCompCodeDroOpts = prevState.dynamicOptions.interCompanyCodeDropdownOptions;
            const customerCodeDroOpts = prevState.dynamicOptions.customerCodeDropdownOptions;
            const currencyCodeDroOpts = prevState.dynamicOptions.currencyCodeDropdownOptions;

            return {
                ...prevState,

                customerAssignmentState: {
                    ...prevState.customerAssignmentState,
                    currentCompanyCust:{
                        ...customerAssign,
                        intercompanyCode: (customerAssign.id && customerAssign.id !== 0)?customerAssign.intercompanyCode:interCompCodeDroOpts[0].value,
                        customerId: (customerAssign.id && customerAssign.id !== 0)?customerAssign.customerId:Number(customerCodeDroOpts[0].tagLabel),
                        currencyCode: (customerAssign.id && customerAssign.id !== 0)?customerAssign.currencyCode:currencyCodeDroOpts[0].value,
                    }
                }
            }
        });
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return companyDetailDispatch(prevState => { 
            let newCustomerId = prevState.customerAssignmentState.currentCompanyCust.customerId;
            if(fieldKey ==='customerId'){
                const customerOptions = prevState.dynamicOptions.customerCodeDropdownOptions;
                if(customerOptions && customerOptions.length > 0 && val){
                    const customer = customerOptions.find(option => option.value === val);

                    if(customer && customer.tagLabel){
                        newCustomerId = Number(customer.tagLabel);
                    }
                }
            }            

            return {
                ...prevState,
                customerAssignmentState: {
                    ...prevState.customerAssignmentState,
                    currentCompanyCust: {
                        ...prevState.customerAssignmentState.currentCompanyCust,
                        [fieldKey]: val,
                        customerId: newCustomerId
                    }
                }            
            }
          });
    }

    const onAdd = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,                
                viewState: {
                    ...prevState.viewState,
                    isAdd: true,
                    isEditable: false,
                    isSaveClicked: false,
                    isShowFsp: false,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: false,
                    isShowCustAssign: true,
                    isShowFactor: false,
                    isShowCustInfo: false,
                    allFormState:{}
                },

                customerAssignmentState: {
                    ...prevState.customerAssignmentState,
                    currentCompanyCust: EMPTY_COMPANY_CUSTOMER_ENTITY
                }
            }
        });
    }
    
    const onEdit = (currentCompanyCust: CompanyCustomerEntity) => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,                
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isEditable: true,
                    isSaveClicked:false,
                    isShowFsp: false,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: false,
                    isShowCustAssign: true,
                    isShowFactor: false,
                    isShowCustInfo: false,
                    allFormState:{}
                },

                customerAssignmentState:{
                    ...prevState.customerAssignmentState,
                    currentCompanyCust: currentCompanyCust
                }
            }
        });
    }

    const onEditCustInfo = (currentCustomer: CustomerEntity) => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: true,                
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isEditable: true,
                    isSaveClicked:false,
                    isShowFsp: false,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: false,
                    isShowCustAssign: false,
                    isShowFactor: false,
                    isShowCustInfo: true,
                    allFormState:{}
                },

                customerInformationState:{
                    ...prevState.customerInformationState,
                    currentCustomer: currentCustomer
                }
            }
        });
    }

    const handleDelete = () => { 
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,

                viewState: {
                    ...prevState.viewState,
                    isShowDeleteModal: true,
                    dataType: "CUSTOMER_ASSIGN"
                }        
            }
        });
    }

    const onDelete = async (selectedRows: CompanyCustomerEntity[]) => {
        await customerAssignmentRepo.deleteCustomerAssigment(
            selectedRows.map(selectedRow => selectedRow.id) as number[]
        ).then((res) => {


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,
                    viewState: {
                        ...prevState.viewState,
                        isShowDeleteModal: false,
                        dataType: ""
                    },
                    
                    customerAssignmentState: {
                        ...prevState.customerAssignmentState,
                        selectedDatas: [],
                        currentCompanyCust: EMPTY_COMPANY_CUSTOMER_ENTITY
                    }
                }
            });
        })
    }    

    const onSelectedCustAssigns = (selectedRows: CompanyCustomerEntity[]) => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                customerAssignmentState:{
                    ...prevState.customerAssignmentState,
                    selectedDatas: selectedRows
                }
            }
        })
    }

    const onSave = async (currentCompanyCust: CompanyCustomerEntity, isAdd: boolean ) => {
        const valResult = await Validation(commonCompCustAssignValidationSchema).ValidateFormOnly(currentCompanyCust);

        if (valResult) {
            let allValResult: {[x: string]: string} = {};  

            if (valResult) {  
                allValResult = { ...allValResult, ...valResult,                    
                    mandatoryCheckFail: 'Please input the missing value.'
                };    
            }


            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    viewState: {
                        ...prevState.viewState,
                        allFormState: {
                            ...allValResult,
                        },

                    }                        
                }
            });
            return { "saveCustomerAssignFailed": 'Please input the missing value.' };
        } else {
            return await (isAdd ? customerAssignmentRepo.addCustomerAssigment(currentCompanyCust) : customerAssignmentRepo.updateCustomerAssigment(currentCompanyCust)).then((data) => {
                if (data && data.toString().startsWith("Error:")) {
                    companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "saveCustomerAssignFailed": data.toString() }
                        };
                    });
                    return { "saveCustomerAssignFailed": data.toString() };
                } else {
                    return companyDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            isShowRightPanel: false,
                            viewState: {
                                ...prevState.viewState,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isSaveClicked: false,
                                lastEditRowId: '',
                                isSliderOpen: false,
                                allFormState: {},

                                isShowFsp: false,
                                isShowEmptyPool: false,
                                isShowSuppleTerms: false,
                                isShowCustAssign: false,
                                isShowFactor: false,
                                isShowCustInfo: false
                            },

                            customerAssignmentState: {
                                ...prevState.customerAssignmentState,
                                selectedDatas: [],
                                currentCompanyCust: EMPTY_COMPANY_CUSTOMER_ENTITY
                            }
                        }
                    })
                } 
            })
        }
    }

    const initialCustAssignTableData = async (compnayId: number) => {
        customerAssignmentRepo.searchCustomerAssigment(compnayId).then(data => {
            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    companyCustomers: data            
                }
            })
        })
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onAdd: onAdd,
        onEdit: onEdit,
        onDelete: onDelete,
        onSelectedCustAssigns: onSelectedCustAssigns, 
        onFieldChange: onFieldChange,
        onSave: onSave,
        initialCustAssignTableData: initialCustAssignTableData,
        onEditCustInfo: onEditCustInfo,
        onInitDefaultValue: onInitDefaultValue,
        handleDelete: handleDelete
    }
}