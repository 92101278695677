import { CompanyConstant, compEmptyPoolRequiredFieldList } from "presentation/constant/Company/CompanyConstant";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCompanyEmptyPoolVM } from "presentation/hook/Company/useCompanyEmptyPoolVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";

import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, HPHInputTextarea, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyEmptyPoolFormPanel = () => {   
    const [ companyDtlState ] = useCompanyDetailTracked();
    const companyEmptyPoolVM = useCompanyEmptyPoolVM();
    const companyDtlVM = useCompanyDetailVM();
    const messageBarVM = useMessageBarVM(); 
    
    const COMPANY_DTL_CONST = CompanyConstant.Detail; 
    const [isLoading, setIsLoading] = useState(false);
    const {dynamicOptions} = companyDtlState
    const {isAdd,isEditable, allFormState, isSaveClicked} = companyDtlState.viewState
    const {currentCompEmptyPool} = companyDtlState.companyEmptyPoolState
    
    useEffect(() => {        
        if (companyDtlState.viewState.isShowEmptyPool) {
            setIsLoading(true);
            companyEmptyPoolVM.loadDropdownOption().then(data=> {
                companyEmptyPoolVM.onInitDefaultValue();
            });
            setIsLoading(false);
        }
    }, [companyDtlState.isShowRightPanel, companyDtlState.viewState.isShowSuppleTerms, companyDtlState.viewState.isShowEmptyPool, companyEmptyPoolVM]);
    
    const isDisabled = useCallback(() => {
        if(currentCompEmptyPool?.status === "FINALIZED" || currentCompEmptyPool?.status === "PROPOSED") return true;

        return false;
    }, [currentCompEmptyPool?.status])

    const handleCancelClick = useCallback(() => {
        companyDtlVM.onCancel();
    },[companyDtlVM])

    const onNumberFieldChange = useCallback((e:any, fieldName:string, fieldValue:any) => {
        companyEmptyPoolVM.onFieldChange(fieldName, fieldValue);
    }, [companyEmptyPoolVM]);

    const handleCntrTextAreaChange = useCallback((event:any, fieldName:string)  => {
        const fieldValue = event.target.value;
        companyEmptyPoolVM.onFieldChange(fieldName, fieldValue);
    },[companyEmptyPoolVM])

    const handleSaveClick = useCallback(() => {
        if(currentCompEmptyPool){
            setIsLoading(true);
            
            if(isAdd){
                currentCompEmptyPool.companyId = companyDtlState.companyId;                
            }
            companyDtlVM.onSaveClicked();
            companyEmptyPoolVM.onSave(currentCompEmptyPool, isAdd).then((data) => {
                if (data && data["saveCompEmptyPoolFailed"]) {
                    setIsLoading(false);
                    messageBarVM.showError(data["saveCompEmptyPoolFailed"]?.toString());
                    return;
                }
                
                companyDtlState.companyId && companyEmptyPoolVM.initialEmptyPoolTableData(companyDtlState.companyId).then(()=>{
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                });
            }).catch(()=>{
                setIsLoading(false);
                messageBarVM.showError('Save Data Failed.');
            });
        }
    },[companyDtlState.companyId, companyDtlVM, companyEmptyPoolVM, currentCompEmptyPool, isAdd, messageBarVM])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isDisabled()}
                    label={COMPANY_DTL_CONST.EFFECTIVE_DATE}
                    width="180px"
                    date={currentCompEmptyPool?.effectiveDate??null}
                    fieldName="effectiveDate"
                    errorMessage={allFormState?allFormState["effectiveDate"]:""}
                    onDateChange={companyEmptyPoolVM.onFieldChange}/>
        </div>
    , [COMPANY_DTL_CONST.EFFECTIVE_DATE, allFormState, companyEmptyPoolVM.onFieldChange, currentCompEmptyPool?.effectiveDate, isDisabled])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompEmptyPool?.operatingTml??''}
                fieldValue={currentCompEmptyPool?.operatingTml??''}
                fieldLabel={COMPANY_DTL_CONST.OPERATING_TML}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                options={dynamicOptions.operatingTmlDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    companyEmptyPoolVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.OPERATING_TML, allFormState, companyEmptyPoolVM, currentCompEmptyPool?.operatingTml, dynamicOptions.operatingTmlDropdownOptions, isDisabled])

    const memoPoolType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompEmptyPool?.poolType??''}
                fieldValue={currentCompEmptyPool?.poolType??''}
                fieldLabel={COMPANY_DTL_CONST.EMPTY_POOL.POOL_TYPE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'poolType'}
                options={dynamicOptions.poolTypeDropdownOptions}
                isSaveClicked={isSaveClicked}
                sort={false}
                maxLength={60}
                requiredFieldList={compEmptyPoolRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    companyEmptyPoolVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.EMPTY_POOL.POOL_TYPE, allFormState, companyEmptyPoolVM, currentCompEmptyPool?.poolType, dynamicOptions.poolTypeDropdownOptions, isDisabled, isSaveClicked])

    const memoPoolSize = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.EMPTY_POOL.POOL_SIZE}
                maxLength={4}
                width="180px"
                fieldName={"poolSize"}
                value={currentCompEmptyPool?.poolSize??''}
                errorMessage={allFormState?.poolSize}
                disabled={isDisabled()}                
                onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
    , [COMPANY_DTL_CONST.EMPTY_POOL.POOL_SIZE, allFormState?.poolSize, currentCompEmptyPool?.poolSize, isDisabled, onNumberFieldChange])

    const memoUom = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompEmptyPool?.uom??''}
                fieldValue={currentCompEmptyPool?.uom??''}
                fieldLabel={COMPANY_DTL_CONST.UOM}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'uom'}
                options={dynamicOptions.poolUomDropdownOptions}
                isSaveClicked={isSaveClicked}
                sort={false}
                maxLength={60}
                requiredFieldList={compEmptyPoolRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    companyEmptyPoolVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.UOM, allFormState, companyEmptyPoolVM, currentCompEmptyPool?.uom, dynamicOptions.poolUomDropdownOptions, isDisabled, isSaveClicked])

    const memoCalculateBy = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompEmptyPool?.calculateBy??''}
                fieldValue={currentCompEmptyPool?.calculateBy??''}
                fieldLabel={COMPANY_DTL_CONST.EMPTY_POOL.CALCULATE_BY}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'calculateBy'}
                options={dynamicOptions.poolCalculateByDropdownOptions}
                isSaveClicked={isSaveClicked}
                sort={false}
                maxLength={60}
                requiredFieldList={compEmptyPoolRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    companyEmptyPoolVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.EMPTY_POOL.CALCULATE_BY, allFormState, companyEmptyPoolVM, currentCompEmptyPool?.calculateBy, dynamicOptions.poolCalculateByDropdownOptions, isDisabled, isSaveClicked])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHInputTextarea
                label={COMPANY_DTL_CONST.REMARKS}
                width="400px"
                rows={3}
                disabled={isDisabled()}
                resizable={false}
                helpIcon={false}
                optional={true}
                value={currentCompEmptyPool?.remarks??''}
                onChange={(e) => handleCntrTextAreaChange(e, "remarks")} />
        </div>
    , [COMPANY_DTL_CONST.REMARKS, currentCompEmptyPool?.remarks, handleCntrTextAreaChange, isDisabled])    

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHInputTextarea
                label={COMPANY_DTL_CONST.REJECT_REASON}
                width="400px"
                rows={3}
                disabled={true}
                resizable={false}
                helpIcon={false}                
                value={currentCompEmptyPool?.rejectReason??''}
                onChange={() =>{}} />           
        </div>
    , [COMPANY_DTL_CONST.REJECT_REASON, currentCompEmptyPool?.rejectReason])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCompEmptyPool?.activeInd??''}
                fieldValue={currentCompEmptyPool?.activeInd??''}
                fieldLabel={COMPANY_DTL_CONST.ACTIVE_IND}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'activeInd'}
                options={dynamicOptions.activeIndDropdownOptions}
                isSaveClicked={isSaveClicked}
                sort={false}
                maxLength={60}
                requiredFieldList={compEmptyPoolRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    companyEmptyPoolVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={COMPANY_DTL_CONST.ACTIVE_IND} 
                fieldType={FieldType.TEXT} 
                isReadOnly={true}
                onFieldChange={()=>{}}/>
            <HPHCheckbox
                errorMessages={allFormState}
                label={''}
                checked={currentCompEmptyPool?.activeInd==='Y'?true:false}
                disabled={false}
                onChange={(e) => companyEmptyPoolVM.onCheckboxChange(e.checked, "activeInd")} />
        </div>
    , [COMPANY_DTL_CONST.ACTIVE_IND, allFormState, companyEmptyPoolVM, currentCompEmptyPool?.activeInd])    

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Pool/Empty Pool'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            
            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoEffectiveDate}
                            {memoOperatingTml}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoPoolType}
                            {memoPoolSize}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoUom}
                            {memoCalculateBy}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRemarks}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRejectReason}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={()=>handleCancelClick()} />
                        <HPHButton label={isAdd?'Add':isEditable?'Update':'Save'} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />
                    </>
                }
            </div>           
            
        </div>
    )
}
export default memo(CompanyEmptyPoolFormPanel);