import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CompanyConstant } from "./CompanyConstant";

const COMPANY_DETAIL_CONSTANT = CompanyConstant.Detail;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_COMPANY_FSP_COL_DEF: any[] = [
    {
        headerName: COMPANY_DETAIL_CONSTANT.PRIORITY,
        field: 'priority',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        dataType: "number",
        cellStyle: { textAlign: 'right' }
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.STATUS,
        field: 'status',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.EFFECTIVE_DATE,
        field: 'effectiveDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.SUB_CHARGE_TYPE,
        field: 'subChargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.SHIPMENT_TYPE,
        field: 'shipmentType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.FIRST_TIER_FSP,
        field: 'firstTierFSP',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "number",
        cellStyle: { textAlign: 'right' },   
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.SECOND_TIER_FSP,
        field: 'secondTierFSP',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "number",
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.UOM,
        field: 'fspUOM',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 100,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.CARGO_CODE,
        field: 'cargoCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.GRACE_HOUR,
        field: 'graceHour',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.GRACE_MIMUTE,
        field: 'graceMinute',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.FSP_COUNTING_FROM,
        field: 'fspCountingFromVal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.FSP_COUNTING_TO,
        field: 'fspCountingToVal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.DIRECTION,
        field: 'fspCountingDirection',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.CALENDAR_ADJ,
        field: 'applyCalendarAdj',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CONFIRM_DATE,
        field: 'confirmedDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"    
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CNTR_MOVEMENT,
        field: 'cntrMovement',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CNTR_STATUS,
        field: 'cntrStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.OPS_LINE,
        field: 'owner',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.FORWARDER_CODE,
        field: 'forwarderCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.FSP.FORWARDER_REF,
        field: 'forwarderReference',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.REMARKS,
        field: 'remarks',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.REJECT_REASON,
        field: 'rejectReason',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,        
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.ACTIVE_IND,
        field: 'activeInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,        
    }

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}