import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyTeuFactorEntity } from "domain/entity/Company/CompanyTeuFactorEntity";
import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { INITIAL_COMPANY_TEU_FACTOR_COL_DEF, transferRowData } from "presentation/constant/Company/CompanyTeuFactorColumnDefinition";
import { useCompanyTeuFactorVM } from "presentation/hook/Company/useCompanyTeuFactorVM";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const CompanyTeuFactorTablePanel:React.FC = () => {
    const [ companyDetailState ] = useCompanyDetailTracked();
    const companyTeuFactorVM = useCompanyTeuFactorVM();

    const [isLoading, setIsLoading] = useState(false);
    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    const {isEditable, isAdd, isEditCompDtlInfo} = companyDetailState.viewState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();
    
    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_COMPANY_TEU_FACTOR_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];

        if (isEditCompDtlInfo) {
            if (!(isAdd || isEditable)) {
                tableBtns.push({
                    id: 'onRefreshButton',
                    icon: 'Icon-reload',
                    title: 'Refresh'
                })
            }
        }

        return tableBtns
    }, [isAdd, isEditCompDtlInfo, isEditable])

    const handleRefresh = useCallback(() => {
        setIsLoading(true);
        companyDetailState.companyId && companyTeuFactorVM.initialTeuFactorTableData(companyDetailState.companyId).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false)
        })
    }, [companyDetailState.companyId, companyTeuFactorVM]);

    const allowDoubleClick = useMemo(() => {
        if(isEditCompDtlInfo && !isAdd && !isEditable){
            return true;
        }

        return false;
    }, [isAdd, isEditCompDtlInfo, isEditable]);

    const handleEdit = useCallback((row: CompanyTeuFactorEntity) => {
        if(allowDoubleClick){
            companyTeuFactorVM.onEdit(row);
        }        
    }, [allowDoubleClick, companyTeuFactorVM]);

    /*const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        if(selectedRows && selectedRows.length > 0){
            companyTeuFactorVM.onSelectedTeuFactors(selectedRows);
        }
    }, [companyTeuFactorVM])*/

    let performedClicks = 0;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent, delay: number = 250) => {
        performedClicks++;
        let clickTimeout = setTimeout(() => {
            if (performedClicks === 1) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                performedClicks = 0;                
                setOnTableSelectionClicked(true);
                
                const selectedRows = e.api.getSelectedRows();
                if(selectedRows && selectedRows.length > 0){
                    companyTeuFactorVM.onSelectedTeuFactors(selectedRows);
                }
            } else {
                performedClicks = 0;
            }
        }, delay);

        if (performedClicks > 1) {
            clearTimeout(clickTimeout);
        }
    }, [companyTeuFactorVM])

    const memoTableTitle = useMemo(() =>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center"}}>
            <HeaderTitle>{COMPANY_DTL_CONST.TEU_FACTOR.TEU_FACTOR}</HeaderTitle>            
        </Sidebarheader>
        , [COMPANY_DTL_CONST.TEU_FACTOR.TEU_FACTOR])
    
    const memoCompanyTeuFactorTable = useMemo(() => {
        return (
        <div style={{marginTop:"50px"}}>
            <HPHTable
                id='company-teu-factor-table'
                isNewColumnSetting={true}
                columns={INITIAL_COMPANY_TEU_FACTOR_COL_DEF.slice()}                
                data={transferRowData(companyDetailState.companyTeuFactors)??[]}
                headerActionButtons={memoTableBtns}
                showPaginator={false}
                editable={false}
                showHeaderIcons={true}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={false}
                selectionMode={false}
                rowSelection={"multiple"}
                onRowDoubleClick={(e: any, row: CompanyTeuFactorEntity) => handleEdit(row)}
                onSelectionChanged={(e: any) =>handleSelectionChange(e, 400)}
                onRefreshButton={handleRefresh}
                //onEditButton={handleEdit}                
                gridHeight="customHeight"
                customHeight="calc(100vh - 700px)" 
                ref={gridRef}
                headerLabel={memoTableTitle}
            />
        </div>
        );
    },[companyDetailState.companyTeuFactors, handleEdit, handleRefresh, handleSelectionChange, memoTableBtns, memoTableTitle])

    return <><TableWrapper>{memoCompanyTeuFactorTable}</TableWrapper>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />} </>;
}

export default memo(CompanyTeuFactorTablePanel);
