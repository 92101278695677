import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyEmptyPoolEntity } from "domain/entity/Company/CompanyEmptyPoolEntity";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { INITIAL_COMPANY_EMPTY_POOL_COL_DEF, transferRowData } from "presentation/constant/Company/CompanyEmptyPoolColumnDefinition";
import { useCompanyEmptyPoolVM } from "presentation/hook/Company/useCompanyEmptyPoolVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, HPHTable, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const CompanyEmptyPoolTablePanel:React.FC = () => {
    const [ companyDetailState ] = useCompanyDetailTracked();
    const [anainfoState] = useANAInfoTracked();
    const companyEmptyPoolVM = useCompanyEmptyPoolVM();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);
    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    const {isAdd,isEditable, isEditCompDtlInfo} = companyDetailState.viewState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const {selectedDatas} = companyDetailState.companyEmptyPoolState
    let gridRef: any = useRef();

    const isReadReject = isPermissionExist(Permission.COMPANY_EMPTY_POOL_REJECT, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isUpdateReject = isPermissionExist(Permission.COMPANY_EMPTY_POOL_REJECT, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isReadApprove = isPermissionExist(Permission.COMPANY_EMPTY_POOL_APPROVE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isUpdateApprove = isPermissionExist(Permission.COMPANY_EMPTY_POOL_APPROVE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    
    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_COMPANY_EMPTY_POOL_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const memoTableBtns = useMemo(()=>{
        const tableBtns = [];

        if (isEditCompDtlInfo) {
            if (!(isAdd || isEditable)) {
                tableBtns.push({
                    id: 'onDeleteButton',
                    icon: 'Icon-trash',
                    title: 'Delete'
                })

                tableBtns.push({
                    id: 'onRefreshButton',
                    icon: 'Icon-reload',
                    title: 'Refresh'
                })
    
                tableBtns.push({
                    id: 'onAddButton',
                    icon: 'Icon-add',
                    title: 'Add'
                })
            }
        }

        return tableBtns
    },[isAdd, isEditCompDtlInfo, isEditable])

    const handleAddClick = useCallback(async() => {
        companyEmptyPoolVM.onAdd();
    },[companyEmptyPoolVM])

    const handleRefresh = useCallback(() => {
        setIsLoading(true);
        companyDetailState.companyId && companyEmptyPoolVM.initialEmptyPoolTableData(companyDetailState.companyId).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false)
        })
    }, [companyDetailState.companyId, companyEmptyPoolVM]);

    const allowDoubleClick = useMemo(() => {
        if(isEditCompDtlInfo && !isAdd && !isEditable){
            return true;
        }

        return false;
    }, [isAdd, isEditCompDtlInfo, isEditable]);

    const handleEdit = useCallback((row: CompanyEmptyPoolEntity) => {
        if(allowDoubleClick){
            companyEmptyPoolVM.onEdit(row);
        }        
    }, [allowDoubleClick, companyEmptyPoolVM]);

    const handleDelete =  useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to delete.");
            return;
        }

        const isAllowDelete = !(selectedDatas.find(selectedData => (selectedData.status === "FINALIZED" ||  selectedData.status === "PROPOSED")));
        if(!isAllowDelete){
            messageBarVM.showWarining("Record status is PROPOSED/FINALIZED, cannot delete.");
            return;
        }

        companyEmptyPoolVM.handleDelete();        
    }, [companyEmptyPoolVM, messageBarVM, selectedDatas]);

    /*const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        if(selectedRows && selectedRows.length > 0){
            companyEmptyPoolVM.onSelectedCompEmptyPools(selectedRows);
        }
    }, [companyEmptyPoolVM])*/

    let performedClicks = 0;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent, delay: number = 250) => {
        performedClicks++;
        let clickTimeout = setTimeout(() => {
            if (performedClicks === 1) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                performedClicks = 0;                
                setOnTableSelectionClicked(true);

                const selectedRows = e.api.getSelectedRows();
                if(selectedRows && selectedRows.length > 0){
                    companyEmptyPoolVM.onSelectedCompEmptyPools(selectedRows);
                }
            } else {
                performedClicks = 0;
            }
        }, delay);

        if (performedClicks > 1) {
            clearTimeout(clickTimeout);
        }
    }, [companyEmptyPoolVM])

    const isDisableSubmit = useCallback(() => {
        if(selectedDatas.length < 1 
            || selectedDatas.find(selectedData => (selectedData.status === "FINALIZED"))){
            return true;
        }

        return false;
    }, [selectedDatas]);

    const isDisableReject = useCallback(() => {
        if(selectedDatas.length < 1 
            || selectedDatas.find(selectedData => (
                !((selectedData.status === "FINALIZED" ||  selectedData.status === "PROPOSED") && selectedData.confirmedDate === null))
        )){
            return true;
        }

        return false;
    }, [selectedDatas]);

    const isDisableApprove = useCallback(() => {
        if(selectedDatas.length < 1 
            || selectedDatas.find(selectedData => (
                !((selectedData.status === "FINALIZED" ||  selectedData.status === "PROPOSED") && selectedData.confirmedDate === null))
        )){
            return true;
        }

        return false;
    }, [selectedDatas]);

    const handleSubmit =  useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to submit.");
            return;
        }

        setIsLoading(true);
        companyEmptyPoolVM.onSubmit(selectedDatas).then(()=>{
            companyDetailState.companyId && companyEmptyPoolVM.initialEmptyPoolTableData(companyDetailState.companyId).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [companyDetailState.companyId, companyEmptyPoolVM, messageBarVM, selectedDatas]);

    const handleReject =  useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to reject.");
            return;
        }

        companyEmptyPoolVM.handleReject();        
    }, [companyEmptyPoolVM, messageBarVM, selectedDatas]);

    const handleApprove =  useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to approve.");
            return;
        }

        setIsLoading(true);
        companyEmptyPoolVM.onApprove(selectedDatas).then(()=>{
            companyDetailState.companyId && companyEmptyPoolVM.initialEmptyPoolTableData(companyDetailState.companyId).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [companyDetailState.companyId, companyEmptyPoolVM, messageBarVM, selectedDatas]);

    const memoTableTitle = useMemo(() =>
        <Sidebarheader style={{ width: (isEditCompDtlInfo?'63vw':'68vw'), display: "flex", alignItems: "center"}}>
            <HeaderTitle>{COMPANY_DTL_CONST.EMPTY_POOL.EMPTY_POOL}</HeaderTitle>
            <StyledAction className="tm-animated-wrapper">
                {
                    <div style={{display:"flex", justifyContent:"flex-end"}}>
                        <HPHButton label={'Submit'} size={'Small'} disabled={isDisableSubmit() || !isEditCompDtlInfo} theme={'Secondary'} onClick={handleSubmit} />
                        {(isReadReject && isUpdateReject) && <HPHButton label={'Reject'} size={'Small'} disabled={isDisableReject()} theme={'Secondary'} onClick={handleReject} />}
                        {(isReadApprove && isUpdateApprove) && <HPHButton label={'Approve'} size={'Small'} disabled={isDisableApprove()} theme={'Primary'} onClick={handleApprove} />}
                    </div>
                }
            </StyledAction>
        </Sidebarheader>
        , [COMPANY_DTL_CONST.EMPTY_POOL.EMPTY_POOL, handleApprove, handleReject, handleSubmit, isDisableApprove, isDisableReject, isDisableSubmit, isEditCompDtlInfo, isReadApprove, isReadReject, isUpdateApprove, isUpdateReject])
    
    const memoCompanyEmptyPoolTable = useMemo(() => {
        return (
        <div style={{marginTop:"50px"}}>
            <HPHTable
                id='company-empty-pool-table'
                isNewColumnSetting={true}
                columns={INITIAL_COMPANY_EMPTY_POOL_COL_DEF.slice()}                
                data={transferRowData(companyDetailState.companyEmptyPools)??[]}
                headerActionButtons={memoTableBtns}
                showPaginator={false}
                editable={false}
                showHeaderIcons={true}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={false}
                selectionMode={false}
                rowSelection={"multiple"}
                onSelectionChanged={(e: any) =>handleSelectionChange(e, 400)}
                onRowDoubleClick={(e: any, row: CompanyEmptyPoolEntity) => handleEdit(row)}
                onAddButton={handleAddClick}
                onRefreshButton={handleRefresh}
                onDeleteButton={handleDelete}
                gridHeight="customHeight"
                customHeight="calc(100vh - 700px)" 
                ref={gridRef}
                headerLabel={memoTableTitle}
            />
        </div>
        );
    },[companyDetailState.companyEmptyPools, handleAddClick, handleDelete, handleEdit, handleRefresh, handleSelectionChange, memoTableBtns, memoTableTitle])

    return <><TableWrapper>{memoCompanyEmptyPoolTable}</TableWrapper>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />} </>;
}

export default memo(CompanyEmptyPoolTablePanel);
