import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CompanyConstant } from "./CompanyConstant";
import { DECIMAL_2 } from "presentation/utils/numberUtil";

const COMPANY_DETAIL_CONSTANT = CompanyConstant.Detail;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
let numberFieldList:string[] = [];
export const INITIAL_COMPANY_TEU_FACTOR_COL_DEF: any[] = [
    {
        headerName: COMPANY_DETAIL_CONSTANT.TEU_FACTOR.SIZE,
        field: 'cntrSize',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        dataType: "number",
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.TEU_FACTOR.FACTOR,
        field: 'teuFactor',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    }

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}