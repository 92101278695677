import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useChargeSuppleTermsVM } from "presentation/hook/Company/useChargeSuppleTermsVM";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCompanyEmptyPoolVM } from "presentation/hook/Company/useCompanyEmptyPoolVM";
import { useCompanyFspVM } from "presentation/hook/Company/useCompanyFspVM";
import { useCustomerAssignmentVM } from "presentation/hook/Company/useCustomerAssignmentVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyDetailTitleBar:React.FC = () => {    
    const [companyDtlState] = useCompanyDetailTracked();
    const companyDtlVM = useCompanyDetailVM();
    const companyFspVM = useCompanyFspVM();
    const companyEmptyPoolVM = useCompanyEmptyPoolVM();
    const chargeSuppleTermsVM = useChargeSuppleTermsVM();
    const customerAssignmentVM = useCustomerAssignmentVM();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);
    const {isShowDeleteModal, dataType} = companyDtlState.viewState;
    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    
    const handleHeaderTxt = () => {
        let headerTxt;

        if(dataType !== "" || dataType.length > 0){
            if(dataType === "FSP"){               
                headerTxt = WorkspaceConstant.Common.BUTTON_DELETE + " " + COMPANY_DTL_CONST.FSP.COMPANY_FSP;
            } else if(dataType === "EMPTY_POOL"){
                headerTxt = WorkspaceConstant.Common.BUTTON_DELETE + " " + COMPANY_DTL_CONST.EMPTY_POOL.EMPTY_POOL;
            } else if(dataType === "CHG_SUPPLE_TERMS"){
                headerTxt = WorkspaceConstant.Common.BUTTON_DELETE + " " + COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.CHG_SUPPLE_TERMS;
            } else if(dataType === "CUSTOMER_ASSIGN"){
                headerTxt = WorkspaceConstant.Common.BUTTON_DELETE + " " + COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_ASSIGN;
            }
        }

        return headerTxt as string;
    }

    const deleteCompanyFsp =  useCallback(() => {
        setIsLoading(true);
        companyFspVM.onDelete(companyDtlState.companyFspState.selectedDatas).then(()=>{
            companyDtlState.companyId && companyFspVM.initialFspTableData(companyDtlState.companyId).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [companyDtlState.companyFspState.selectedDatas, companyDtlState.companyId, companyFspVM, messageBarVM]);

    const deleteCompEmptyPool =  useCallback(() => {
        setIsLoading(true);
        companyEmptyPoolVM.onDelete(companyDtlState.companyEmptyPoolState.selectedDatas).then(()=>{
            companyDtlState.companyId && companyEmptyPoolVM.initialEmptyPoolTableData(companyDtlState.companyId).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [companyDtlState.companyEmptyPoolState.selectedDatas, companyDtlState.companyId, companyEmptyPoolVM, messageBarVM]);

    const deleteCompChgSuppleTerms =  useCallback(() => {
        setIsLoading(true);
        chargeSuppleTermsVM.onDelete(companyDtlState.chargeSuppleTermsState.selectedDatas).then(()=>{
            companyDtlState.companyId && chargeSuppleTermsVM.initialChgSuppleTermsTableData(companyDtlState.companyId).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [chargeSuppleTermsVM, companyDtlState.chargeSuppleTermsState.selectedDatas, companyDtlState.companyId, messageBarVM]);

    const deleteCompCustAssign =  useCallback(() => {
        setIsLoading(true);
        customerAssignmentVM.onDelete(companyDtlState.customerAssignmentState.selectedDatas).then(()=>{
            companyDtlState.companyId && customerAssignmentVM.initialCustAssignTableData(companyDtlState.companyId).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [companyDtlState.companyId, companyDtlState.customerAssignmentState.selectedDatas, customerAssignmentVM, messageBarVM]);

    const handleCancel = useCallback(() => {
        companyDtlVM.onCancelDelete();
    }, [companyDtlVM])

    const handleDelete = useCallback(() => {
        if(dataType !== "" || dataType.length > 0){
            if(dataType === "FSP"){               
                deleteCompanyFsp();
            } else if(dataType === "EMPTY_POOL"){
                deleteCompEmptyPool();
            } else if(dataType === "CHG_SUPPLE_TERMS"){
                deleteCompChgSuppleTerms();
            } else if(dataType === "CUSTOMER_ASSIGN"){
                deleteCompCustAssign();
            }
        }
    }, [dataType, deleteCompChgSuppleTerms, deleteCompCustAssign, deleteCompEmptyPool, deleteCompanyFsp])

    return <Sidebarheader style={{width: '100%'}}>
    <HeaderWithBackButton callback={companyDtlVM.onCloseScreen}>
    <Breadcrumb>
        <HPHBreadcrumb breadcrumbData={[ { title: CompanyConstant.Header.COMPANY }, { title: CompanyConstant.Header.COMPANY_MAIN }]} onCurrentClick={()=>{}} ></HPHBreadcrumb>
    </Breadcrumb>
    </HeaderWithBackButton>    
    <StyledAction className="tm-animated-wrapper">
        <ConfirmDeleteModal headerText={handleHeaderTxt()} 
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDeleteModal}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
    </StyledAction>
    {isLoading && <Loader Indicator="Spinner" size="Medium" />}
</Sidebarheader>
}

export default memo(CompanyDetailTitleBar);