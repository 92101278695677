import { CompanyFspEntity } from "domain/entity/Company/CompanyFspEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";

import { CompanyFspRepository } from "./CompanyFspRepo";

export const CompanyFspRepoImpl = (): CompanyFspRepository => {
    const url = '/v1/companyFsp';

    const searchCompanyFsp = async(companyId: number) : Promise<CompanyFspEntity[]> => {
        const newUrl = url + '/search/';
        const result = axiosGetData(companyAxiosInstance, `${newUrl}` + companyId).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    //errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const addCompanyFsp = async(newData: CompanyFspEntity) : Promise<CompanyFspEntity> => {
        const newUrl = url + '/add';        

        return await axiosPostData(companyAxiosInstance, `${newUrl}`, newData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to add company fsp."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }
    
    const updateCompanyFsp = async(updatedData: CompanyFspEntity): Promise<CompanyFspEntity> => {
        const newUrl = url + '/update';        

        return await axiosPutData(companyAxiosInstance, `${newUrl}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to update company fsp."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const batchUpdateCompanyFsp = async(updateCompanyFsps: CompanyFspEntity[]): Promise<Boolean> => {
        const newUrl = url + '/batchUpdate';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, updateCompanyFsps).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteCompanyFsp = async(compFspIds: number[]): Promise<Boolean> => {
        const newUrl = url + '/delete';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, compFspIds).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    return {
        searchCompanyFsp: searchCompanyFsp,
        addCompanyFsp: addCompanyFsp,
        updateCompanyFsp: updateCompanyFsp,
        deleteCompanyFsp: deleteCompanyFsp,
        batchUpdateCompanyFsp: batchUpdateCompanyFsp
    }
}