import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCompanyTeuFactorVM } from "presentation/hook/Company/useCompanyTeuFactorVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";

import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyTeuFactorFormPanel = () => {   
    const [ companyDtlState ] = useCompanyDetailTracked();
    const companyTeuFactorVM = useCompanyTeuFactorVM();
    const companyDtlVM = useCompanyDetailVM()
    const messageBarVM = useMessageBarVM();

    const COMPANY_DTL_CONST = CompanyConstant.Detail;    
    const [isLoading, setIsLoading] = useState(false);
    const {dynamicOptions,companyId} = companyDtlState
    const {isAdd,isEditable, allFormState} = companyDtlState.viewState
    const {currentCompTeuFactor} = companyDtlState.companyTeuFactorState

    useEffect(() => {
        if (companyDtlState.viewState.isShowFactor) {            
            companyTeuFactorVM.loadDropdownOption(companyId);
        }        
    }, [companyDtlState.isShowRightPanel, companyDtlState.viewState.isShowFactor, companyId, companyTeuFactorVM]);

    const handleSaveClick = useCallback(() => {
        if(currentCompTeuFactor){
            setIsLoading(true);
            
            if(isAdd){
                currentCompTeuFactor.companyId = companyId;
            }
            companyTeuFactorVM.onSave(currentCompTeuFactor, isAdd).then((data) => {
                if (data && data["saveCompTeuFactorFailed"]) {
                    setIsLoading(false);
                    messageBarVM.showError(data["saveCompTeuFactorFailed"]?.toString());
                    return;
                }
                
                companyId && companyTeuFactorVM.initialTeuFactorTableData(companyId).then(()=>{
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                });
            }).catch(()=>{
                setIsLoading(false);
                messageBarVM.showError('Save Data Failed.');
            });
        }
    },[companyId, companyTeuFactorVM, currentCompTeuFactor, isAdd, messageBarVM])

    const handleCancelClick = useCallback(() => {
        companyDtlVM.onCancel();
    }, [companyDtlVM]) 
    
    const onNumberFieldChange = useCallback((e:any, fieldName:string, fieldValue:any) => {
        companyTeuFactorVM.onFieldChange(fieldName, fieldValue);
    }, [companyTeuFactorVM]);

    const memoSize = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd || isEditable}
                isShowOptional={true}
                readOnlyValue={currentCompTeuFactor?.cntrSize as unknown as string}
                fieldValue={currentCompTeuFactor?.cntrSize as unknown as string}
                fieldLabel={COMPANY_DTL_CONST.TEU_FACTOR.SIZE}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrSize'}
                options={dynamicOptions.sizeDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}
                    /*manualChargeDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)*/} />
        </div>
    , [COMPANY_DTL_CONST.TEU_FACTOR.SIZE, allFormState, currentCompTeuFactor?.cntrSize, dynamicOptions.sizeDropdownOptions, isAdd, isEditable])

    const memoTeuFactor = useMemo(() =>
        <div className='im-flex-row-item'>            
            <NumberInputComponent
                label={COMPANY_DTL_CONST.TEU_FACTOR.FACTOR}
                maxLength={60}
                width='150px'
                fieldName={"teuFactor"}
                value={currentCompTeuFactor?.teuFactor??''}
                errorMessage={allFormState?.teuFactor}
                //disabled={isRead || !!currentManChgDtl.cntrList}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
    , [COMPANY_DTL_CONST.TEU_FACTOR.FACTOR, allFormState?.teuFactor, currentCompTeuFactor?.teuFactor, onNumberFieldChange])    

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Teu Factor'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            
            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoSize}
                            {memoTeuFactor}
                        </CriteriaItemContainer>                        
                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={()=>(handleCancelClick())} />
                        <HPHButton label={isAdd?'Add':isEditable?'Update':'Save'} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />
                    </>
                }
            </div>           
            
        </div>
    )
}
export default memo(CompanyTeuFactorFormPanel);