import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCustomerAssignmentVM } from "presentation/hook/Company/useCustomerAssignmentVM";
import { useCustomerVM } from "presentation/hook/Company/useCustomerVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";

import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CustomerInformationFormPanel = () => {   
    const [ companyDtlState ] = useCompanyDetailTracked();    
    const companyDtlVM = useCompanyDetailVM();
    const customerVM = useCustomerVM();
    const customerAssignVM = useCustomerAssignmentVM();
    const messageBarVM = useMessageBarVM(); 

    const COMPANY_DTL_CONST = CompanyConstant.Detail;     
    const [isLoading, setIsLoading] = useState(false);
    const {dynamicOptions} = companyDtlState
    const { isEditable, allFormState } = companyDtlState.viewState
    const {currentCustomer} = companyDtlState.customerInformationState

    useEffect(() => {
        if (companyDtlState.viewState.isShowCustInfo) {            
            customerVM.loadDropdownOption();
        }        
    }, [companyDtlState.viewState.isShowCustInfo, customerVM]);

    const handleCancelClick = useCallback(() => {
        companyDtlVM.onCancel();
    },[companyDtlVM])

    const handleSaveClick = useCallback(() => {
        if(currentCustomer){
            setIsLoading(true);
            
            customerVM.onSave(currentCustomer).then((data) => {
                if (data && data["saveCustomerInfoFailed"]) {
                    setIsLoading(false);
                    messageBarVM.showError(data["saveCustomerInfoFailed"]?.toString());
                    return;
                }    
                
                customerAssignVM.initialCustAssignTableData(companyDtlState.companyId).then(() => {
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                });
                
            }).catch(()=>{
                setIsLoading(false);
                messageBarVM.showError('Save Data Failed.');
            });
        }
    },[companyDtlState.companyId, currentCustomer, customerAssignVM, customerVM, messageBarVM])

    const onNumberFieldChange = useCallback((e:any, fieldName:string, fieldValue:any) => {
        customerVM.onFieldChange(fieldName, fieldValue);
    }, [customerVM]);

    const memoContactPerson = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.contactPerson??''}
                fieldValue={currentCustomer?.contactPerson??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CONTACT_PERSON}                
                fieldType={FieldType.TEXT}
                fieldKey={'contactPerson'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    customerVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CONTACT_PERSON, allFormState, currentCustomer?.contactPerson, customerVM])

    const memoCustomerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.customerCode??''}
                fieldValue={currentCustomer?.customerCode??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_CODE}                
                fieldType={FieldType.TEXT}
                fieldKey={'customerCode'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_CODE, allFormState, currentCustomer?.customerCode, isEditable])

    const memoType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.type??''}
                fieldValue={currentCustomer?.type??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.TYPE}                
                fieldType={FieldType.TEXT}
                fieldKey={'type'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.TYPE, allFormState, currentCustomer?.type, isEditable])

    const memoCustomerNtnNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.ntnNo??''}
                fieldValue={currentCustomer?.ntnNo??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_NTN_NO}                
                fieldType={FieldType.TEXT}
                fieldKey={'ntnNo'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_NTN_NO, allFormState, currentCustomer?.ntnNo, isEditable])

    const memoCustomerGstNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.gstNo??''}
                fieldValue={currentCustomer?.gstNo??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_GST_NO}                
                fieldType={FieldType.TEXT}
                fieldKey={'gstNo'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_GST_NO, allFormState, currentCustomer?.gstNo, isEditable])

    const memoCustomerAddress = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.customerAddress??''}
                fieldValue={currentCustomer?.customerAddress??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_ADDRESS}                
                fieldType={FieldType.TEXT}
                fieldKey={'customerAddress'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_ADDRESS, allFormState, currentCustomer?.customerAddress, isEditable])

    const memoCustomerEmailAddress = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.emailAddress??''}
                fieldValue={currentCustomer?.emailAddress??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_EMAIL_ADDRESS}                
                fieldType={FieldType.TEXT}
                fieldKey={'emailAddress'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_EMAIL_ADDRESS, allFormState, currentCustomer?.emailAddress, isEditable])

    const memoCustomerName1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={/*currentCustomer?.customerDesc1??''*/
                    (currentCustomer?.customerDesc1 && currentCustomer?.customerDesc1?.length <=15 ? currentCustomer?.customerDesc1 : (currentCustomer?.customerDesc1?.substring(0, 15) + '...')) ?? ''
                }
                fieldValue={currentCustomer?.customerDesc1??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_NAME_1}                
                fieldType={FieldType.TEXT}
                fieldKey={'customerDesc1'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_NAME_1, allFormState, currentCustomer?.customerDesc1, isEditable])

    const memoCustomerName2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={
                    (currentCustomer?.customerDesc2 && currentCustomer?.customerDesc2?.length <=15 ? currentCustomer?.customerDesc2 : (currentCustomer?.customerDesc2?.substring(0, 15) + '...')) ?? ''
                }
                fieldValue={currentCustomer?.customerDesc2??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_NAME_2}                
                fieldType={FieldType.TEXT}
                fieldKey={'customerDesc2'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CUSTOMER_NAME_2, allFormState, currentCustomer?.customerDesc2, isEditable])

    const memoNifNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.nif??''}
                fieldValue={currentCustomer?.nif??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.N_I_F_NUMBER}                
                fieldType={FieldType.TEXT}
                fieldKey={'nif'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.N_I_F_NUMBER, allFormState, currentCustomer?.nif, isEditable])

    const memoPaymentCutOffDay1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.PAYMENT_CUT_OFF_DAY_1}
                maxLength={60}
                width='150px'
                fieldName={"paymentCutoffDay1"}
                value={currentCustomer?.paymentCutoffDay1??''}
                errorMessage={allFormState?.paymentCutoffDay1}
                disabled={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.PAYMENT_CUT_OFF_DAY_1, allFormState?.paymentCutoffDay1, currentCustomer?.paymentCutoffDay1])

    const memoPaymentCutOffDay2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.PAYMENT_CUT_OFF_DAY_2}
                maxLength={60}
                width='150px'
                fieldName={"paymentCutoffDay2"}
                value={currentCustomer?.paymentCutoffDay2??''}
                errorMessage={allFormState?.paymentCutoffDay2}
                disabled={true}                
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.PAYMENT_CUT_OFF_DAY_2, allFormState?.paymentCutoffDay2, currentCustomer?.paymentCutoffDay2])

    const memoPaymentCutOffDay3 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.PAYMENT_CUT_OFF_DAY_3}
                maxLength={60}
                width='150px'
                fieldName={"paymentCutoffDay3"}
                value={currentCustomer?.paymentCutoffDay3??''}
                errorMessage={allFormState?.paymentCutoffDay3}
                disabled={true}                
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.PAYMENT_CUT_OFF_DAY_3, allFormState?.paymentCutoffDay3, currentCustomer?.paymentCutoffDay3])

    const memoPaymentTerm = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.PAYMENT_TERM}
                maxLength={60}
                width='150px'
                fieldName={"paymentTerm"}
                value={currentCustomer?.paymentTerm??''}
                errorMessage={allFormState?.paymentTerm}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.PAYMENT_TERM, allFormState?.paymentTerm, currentCustomer?.paymentTerm])

    const memoInterestRate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.INTEREST_RATE}
                maxLength={60}
                width='150px'
                fieldName={"interestRate"}
                value={currentCustomer?.interestRate??''}
                errorMessage={allFormState?.interestRate}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.INTEREST_RATE, allFormState?.interestRate, currentCustomer?.interestRate])

    const memoStatementAddress1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.statementAddress1??''}
                fieldValue={currentCustomer?.statementAddress1??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.STATEMENT_ADDRESS_1}                
                fieldType={FieldType.TEXT}
                fieldKey={'statementAddress1'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.STATEMENT_ADDRESS_1, allFormState, currentCustomer?.statementAddress1, isEditable])

    const memoStatementAddress2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.statementAddress2??''}
                fieldValue={currentCustomer?.statementAddress2??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.STATEMENT_ADDRESS_2}                
                fieldType={FieldType.TEXT}
                fieldKey={'statementAddress2'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.STATEMENT_ADDRESS_2, allFormState, currentCustomer?.statementAddress2, isEditable])

    const memoStatementAddress3 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.statementAddress3??''}
                fieldValue={currentCustomer?.statementAddress3??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.STATEMENT_ADDRESS_3}                
                fieldType={FieldType.TEXT}
                fieldKey={'statementAddress3'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.STATEMENT_ADDRESS_3, allFormState, currentCustomer?.statementAddress3, isEditable])

    const memoBillingAddress1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.billingAddress1??''}
                fieldValue={currentCustomer?.billingAddress1??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.BILLING_ADDRESS_1}                
                fieldType={FieldType.TEXT}
                fieldKey={'billingAddress1'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.BILLING_ADDRESS_1, allFormState, currentCustomer?.billingAddress1, isEditable])

    const memoBillingAddress2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.billingAddress2??''}
                fieldValue={currentCustomer?.billingAddress2??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.BILLING_ADDRESS_2}                
                fieldType={FieldType.TEXT}
                fieldKey={'billingAddress2'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.BILLING_ADDRESS_2, allFormState, currentCustomer?.billingAddress2, isEditable])

    const memoBillingAddress3 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.billingAddress3??''}
                fieldValue={currentCustomer?.billingAddress3??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.BILLING_ADDRESS_3}                
                fieldType={FieldType.TEXT}
                fieldKey={'billingAddress3'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.BILLING_ADDRESS_3, allFormState, currentCustomer?.billingAddress3, isEditable])

    const memoBillingAddress4 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.billingAddress4??''}
                fieldValue={currentCustomer?.billingAddress4??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.BILLING_ADDRESS_4}                
                fieldType={FieldType.TEXT}
                fieldKey={'billingAddress4'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.BILLING_ADDRESS_4, allFormState, currentCustomer?.billingAddress4, isEditable])

    const memoCity = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.city??''}
                fieldValue={currentCustomer?.city??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.CITY}                
                fieldType={FieldType.TEXT}
                fieldKey={'city'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CITY, allFormState, currentCustomer?.city, isEditable])

    const memoCounty = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.county??''}
                fieldValue={currentCustomer?.county??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.COUNTY}                
                fieldType={FieldType.TEXT}
                fieldKey={'county'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.COUNTY, allFormState, currentCustomer?.county, isEditable])

    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.state??''}
                fieldValue={currentCustomer?.state??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.STATE}                
                fieldType={FieldType.TEXT}
                fieldKey={'state'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.STATE, allFormState, currentCustomer?.state, isEditable])

    const memoProvince = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.province??''}
                fieldValue={currentCustomer?.province??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.PROVINCE}                
                fieldType={FieldType.TEXT}
                fieldKey={'province'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.PROVINCE, allFormState, currentCustomer?.province, isEditable])

    const memoPostalCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.postalCode??''}
                fieldValue={currentCustomer?.postalCode??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.POSTAL_CODE}                
                fieldType={FieldType.TEXT}
                fieldKey={'postalCode'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.POSTAL_CODE, allFormState, currentCustomer?.postalCode, isEditable])

    const memoCountry = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.country??''}
                fieldValue={currentCustomer?.country??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.COUNTRY}                
                fieldType={FieldType.TEXT}
                fieldKey={'country'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.COUNTRY, allFormState, currentCustomer?.country, isEditable])

    const memoTel = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.telephoneNo??''}
                fieldValue={currentCustomer?.telephoneNo??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.TEL}                
                fieldType={FieldType.TEXT}
                fieldKey={'telephoneNo'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.TEL, allFormState, currentCustomer?.telephoneNo, isEditable])

    const memoFax = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.faxNo??''}
                fieldValue={currentCustomer?.faxNo??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.FAX}                
                fieldType={FieldType.TEXT}
                fieldKey={'faxNo'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.FAX, allFormState, currentCustomer?.faxNo, isEditable])

    const memoRegion = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}                
                readOnlyValue={currentCustomer?.region??''}
                fieldValue={currentCustomer?.region??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.REGION}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'region'}
                disableInput={true}
                options={dynamicOptions.regionDropdownOptions} 
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    customerVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)
                } />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.REGION, allFormState, currentCustomer?.region, customerVM, dynamicOptions.regionDropdownOptions])

    const memoGlAccount = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.glAccount??''}
                fieldValue={currentCustomer?.glAccount??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.GL_ACCOUNT_CODE}                
                fieldType={FieldType.TEXT}
                fieldKey={'glAccount'}
                options={[]} 
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.GL_ACCOUNT_CODE, allFormState, currentCustomer?.glAccount, isEditable])

    const memoCreditLimit = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.CREDIT_LIMIT}
                maxLength={60}
                width='150px'
                fieldName={"creditLimit"}
                value={currentCustomer?.creditLimit??''}
                errorMessage={allFormState?.creditLimit}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CREDIT_LIMIT, allFormState?.creditLimit, currentCustomer?.creditLimit, onNumberFieldChange])

    const memoOutstandingBal = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.OUTSTANDING_BALANCE}
                maxLength={60}
                width='150px'
                fieldName={"outstandingBal"}
                value={currentCustomer?.outstandingBal??''}
                errorMessage={allFormState?.outstandingBal}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.OUTSTANDING_BALANCE, allFormState?.outstandingBal, currentCustomer?.outstandingBal])

    const memoNetBal = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.NET_BALANCE}
                maxLength={60}
                width='150px'
                fieldName={"netBal"}
                value={currentCustomer?.netBal??''}
                errorMessage={allFormState?.netBal}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.NET_BALANCE, allFormState?.netBal, currentCustomer?.netBal])

    const memoUnFinalizedAmount = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.UN_FINALIZED_AMOUNT}
                maxLength={60}
                width='150px'
                fieldName={"unFinalizedAmount"}
                value={currentCustomer?.unFinalizedAmount??''}
                errorMessage={allFormState?.unFinalizedAmount}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.UN_FINALIZED_AMOUNT, allFormState?.unFinalizedAmount, currentCustomer?.unFinalizedAmount])

    const memoCreditLimitExceeded = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CUSTOMER.CREDIT_LIMIT_EXCEEDED}
                maxLength={60}
                width='150px'
                fieldName={"creditLimitExceeded"}
                value={currentCustomer?.creditLimitExceeded??''}
                errorMessage={allFormState?.creditLimitExceeded}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => {}}
            />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.CREDIT_LIMIT_EXCEEDED, allFormState?.creditLimitExceeded, currentCustomer?.creditLimitExceeded])
    
    const memoShippingLineAddress1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.shippingLineAddress1??''}
                fieldValue={currentCustomer?.shippingLineAddress1??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.SHIPPING_LINE_ADDRESS_1}                
                fieldType={FieldType.TEXT}
                fieldKey={'shippingLineAddress1'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.SHIPPING_LINE_ADDRESS_1, allFormState, currentCustomer?.shippingLineAddress1, isEditable])
    
    const memoShippingLineAddress2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.shippingLineAddress2??''}
                fieldValue={currentCustomer?.shippingLineAddress2??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.SHIPPING_LINE_ADDRESS_2}                
                fieldType={FieldType.TEXT}
                fieldKey={'shippingLineAddress2'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.SHIPPING_LINE_ADDRESS_2, allFormState, currentCustomer?.shippingLineAddress2, isEditable])

    const memoShippingLineAddress3 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentCustomer?.shippingLineAddress3??''}
                fieldValue={currentCustomer?.shippingLineAddress3??''}
                fieldLabel={COMPANY_DTL_CONST.CUSTOMER.SHIPPING_LINE_ADDRESS_3}                
                fieldType={FieldType.TEXT}
                fieldKey={'shippingLineAddress3'}                
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {}} />
        </div>
    , [COMPANY_DTL_CONST.CUSTOMER.SHIPPING_LINE_ADDRESS_3, allFormState, currentCustomer?.shippingLineAddress3, isEditable])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px"}}>            
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                disableInput={true}
                readOnlyValue={currentCustomer?.activeInd??''}
                fieldValue={currentCustomer?.activeInd??''}
                fieldLabel={COMPANY_DTL_CONST.ACTIVE_IND}                
                fieldType={FieldType.DROPDOWN}
                fieldKey={'activeInd'}
                options={dynamicOptions.activeIndDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    customerVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)
                }/>*/}
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={COMPANY_DTL_CONST.ACTIVE_IND} 
                fieldType={FieldType.TEXT} 
                isReadOnly={true}
                onFieldChange={()=>{}}/>
            <HPHCheckbox
                errorMessages={allFormState}
                label={''}
                checked={currentCustomer?.activeInd==='Y'?true:false}
                disabled={false}
                onChange={(e) => customerVM.onCheckboxChange(e.checked, "activeInd")} />
        </div>
    , [COMPANY_DTL_CONST.ACTIVE_IND, allFormState, currentCustomer?.activeInd, customerVM])

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Customer Assignment'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            
            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoContactPerson}
                            {memoCustomerCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoType}
                            {memoRegion}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCustomerNtnNo}
                            {memoCustomerGstNo}
                        </CriteriaItemContainer> 

                        <CriteriaItemContainer>
                            {memoCustomerAddress}
                            {memoCustomerEmailAddress}
                        </CriteriaItemContainer>                       

                        <CriteriaItemContainer>
                            {memoCustomerName1}
                            {memoCustomerName2}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoNifNo}
                            {memoPaymentCutOffDay1}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoPaymentTerm}
                            {memoPaymentCutOffDay2}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoInterestRate}
                            {memoPaymentCutOffDay3}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoStatementAddress1}
                            {memoShippingLineAddress1}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoStatementAddress2}
                            {memoShippingLineAddress2}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoStatementAddress3}
                            {memoShippingLineAddress3}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCity}
                            {memoCounty}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoState}
                            {memoProvince}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoPostalCode}
                            {memoCountry}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTel}
                            {memoFax}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>                            
                            {memoActiveInd}
                            {memoCreditLimit}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoBillingAddress1}
                            {memoOutstandingBal}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoBillingAddress2}
                            {memoNetBal}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoBillingAddress3}
                            {memoUnFinalizedAmount}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoBillingAddress4}
                            {memoCreditLimitExceeded}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoGlAccount}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={()=>handleCancelClick()} />
                        <HPHButton label={'Update'} size={'Small'} theme={'Primary'} onClick={()=>(handleSaveClick())} />
                    </>
                }
            </div>           
            
        </div>
    )
}
export default memo(CustomerInformationFormPanel);