import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyFspEntity } from "domain/entity/Company/CompanyFspEntity";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { INITIAL_COMPANY_FSP_COL_DEF, transferRowData } from "presentation/constant/Company/CompanyFspColumnDefinition";
import { useCompanyFspVM } from "presentation/hook/Company/useCompanyFspVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, HPHTable, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyFspTablePanel:React.FC = () => {
    const [ companyDetailState ] = useCompanyDetailTracked();
    const [anainfoState] = useANAInfoTracked();
    const companyFspVM = useCompanyFspVM();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);
    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const {isAdd,isEditable, isEditCompDtlInfo} = companyDetailState.viewState;
    const {selectedDatas} = companyDetailState.companyFspState

    const isReadReject = isPermissionExist(Permission.COMPANY_FSP_REJECT, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isUpdateReject = isPermissionExist(Permission.COMPANY_FSP_REJECT, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isReadApprove = isPermissionExist(Permission.COMPANY_FSP_APPROVE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isUpdateApprove = isPermissionExist(Permission.COMPANY_FSP_APPROVE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);


    let gridRef: any = useRef();
    
    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_COMPANY_FSP_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const memoTableBtns = useMemo(()=>{
        const tableBtns = [];
        
        if (isEditCompDtlInfo) {
            if (!(isAdd || isEditable)) {
                tableBtns.push({
                    id: 'onDeleteButton',
                    icon: 'Icon-trash',
                    title: 'Delete'
                })

                tableBtns.push({
                    id: 'onRefreshButton',
                    icon: 'Icon-reload',
                    title: 'Refresh'
                })
    
                tableBtns.push({
                    id: 'onAddButton',
                    icon: 'Icon-add',
                    title: 'Add'
                })
            }
        }

        return tableBtns
    },[isAdd, isEditCompDtlInfo, isEditable]) 

    const handleRefresh = useCallback(() => {
        setIsLoading(true);
        companyDetailState.companyId && companyFspVM.initialFspTableData(companyDetailState.companyId).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false)
        })
    }, [companyDetailState.companyId, companyFspVM]);

    const handleAdd = useCallback(async() => {
        companyFspVM.onAdd();
    },[companyFspVM])

    const allowDoubleClick = useMemo(() => {
        if(isEditCompDtlInfo && !isAdd && !isEditable){
            return true;
        }

        return false;
    }, [isAdd, isEditCompDtlInfo, isEditable]);

    const handleEdit = useCallback((row: CompanyFspEntity) => {
        if(allowDoubleClick){            
            companyFspVM.onEdit(row);
        }
    }, [allowDoubleClick, companyFspVM]);

    const handleDelete =  useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to delete.");
            return;
        }

        const isAllowDelete = !(selectedDatas.find(selectedData => (selectedData.status === "FINALIZED" ||  selectedData.status === "PROPOSED")));
        if(!isAllowDelete){
            messageBarVM.showWarining("Record status is PROPOSED/FINALIZED, cannot delete.");
            return;
        }

        companyFspVM.handleDelete();
    }, [companyFspVM, messageBarVM, selectedDatas]);

    const handleSubmit =  useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to submit.");
            return;
        }

        setIsLoading(true);
        companyFspVM.onSubmit(selectedDatas).then(()=>{
            companyDetailState.companyId && companyFspVM.initialFspTableData(companyDetailState.companyId).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [companyDetailState.companyId, companyFspVM, messageBarVM, selectedDatas]);

    const handleReject =  useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to reject.");
            return;
        }
        
        companyFspVM.handleReject();        
    }, [companyFspVM, messageBarVM, selectedDatas]);

    const handleApprove =  useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showWarining("Please select a record(s) to approve.");
            return;
        }

        setIsLoading(true);
        companyFspVM.onApprove(selectedDatas).then(()=>{
            companyDetailState.companyId && companyFspVM.initialFspTableData(companyDetailState.companyId).then(()=>{
                setIsLoading(false);
            }).catch((error) => {                    
                setIsLoading(false)
            })
        }).catch((error)=>{
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })
        
    }, [companyDetailState.companyId, companyFspVM, messageBarVM, selectedDatas]);

    /*const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        if(selectedRows && selectedRows.length > 0){
            companyFspVM.onSelectedCompFsps(selectedRows);
        }
    }, [companyFspVM])*/

    let performedClicks = 0;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent, delay: number = 250) => {
        performedClicks++;
        let clickTimeout = setTimeout(() => {
            if (performedClicks === 1) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                performedClicks = 0;                
                setOnTableSelectionClicked(true);

                const selectedRows = e.api.getSelectedRows();
                if(selectedRows && selectedRows.length > 0){
                    companyFspVM.onSelectedCompFsps(selectedRows);
                }
            } else {
                performedClicks = 0;
            }
        }, delay);

        if (performedClicks > 1) {
            clearTimeout(clickTimeout);
        }
    }, [companyFspVM])

    const isDisableSubmit = useCallback(() => {
        if(selectedDatas.length < 1 
            || selectedDatas.find(selectedData => (selectedData.status === "FINALIZED"))){
            return true;
        }

        return false;
    }, [selectedDatas]);

    const isDisableReject = useCallback(() => {
        if(selectedDatas.length < 1 
            || selectedDatas.find(selectedData => (
                !((selectedData.status === "FINALIZED" ||  selectedData.status === "PROPOSED") && selectedData.confirmedDate === null))
        )){
            return true;
        }

        return false;
    }, [selectedDatas]);

    const isDisableApprove = useCallback(() => {
        if(selectedDatas.length < 1 
            || selectedDatas.find(selectedData => (
                !((selectedData.status === "FINALIZED" ||  selectedData.status === "PROPOSED") && selectedData.confirmedDate === null))
        )){
            return true;
        }

        return false;
    }, [selectedDatas]);

    const memoTableTitle = useMemo(() =>
        <Sidebarheader style={{ width: (isEditCompDtlInfo ? '63vw' : '68vw'), display: "flex", alignItems: "center" }}>
            <HeaderTitle>{COMPANY_DTL_CONST.FSP.COMPANY_FSP}</HeaderTitle>
            <StyledAction>
                {
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <HPHButton label={'Submit'} size={'Small'} disabled={isDisableSubmit() || !isEditCompDtlInfo} theme={'Secondary'} onClick={handleSubmit} />
                        {(isReadReject && isUpdateReject) && <HPHButton label={'Reject'} size={'Small'} disabled={isDisableReject()} theme={'Secondary'} onClick={handleReject} />}
                        {(isReadApprove && isUpdateApprove) && <HPHButton label={'Approve'} size={'Small'} disabled={isDisableApprove()} theme={'Primary'} onClick={handleApprove} />}
                    </div>
                }
            </StyledAction>
        </Sidebarheader>
        , [COMPANY_DTL_CONST.FSP.COMPANY_FSP, handleApprove, handleReject, handleSubmit, isDisableApprove, isDisableReject, isDisableSubmit, isEditCompDtlInfo, isReadApprove, isReadReject, isUpdateApprove, isUpdateReject])

    const memoCompanyFspTable = useMemo(() => {
        return (
            <>
                {<HPHTable
                    id='company-fsp-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_COMPANY_FSP_COL_DEF.slice()}
                    data={transferRowData(companyDetailState.companyFsps) ?? []}
                    headerActionButtons={memoTableBtns}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={false}
                    selectionMode={false}
                    rowSelection={"multiple"}
                    onSelectionChanged={(e: any) =>handleSelectionChange(e, 400)}
                    onRowDoubleClick={(e: any, row: CompanyFspEntity) => handleEdit(row)}                    
                    onAddButton={handleAdd}
                    onRefreshButton={handleRefresh}                    
                    onDeleteButton={handleDelete}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 700px)"
                    ref={gridRef}
                    headerLabel={memoTableTitle}
                    /*isRowDrag={true}
                    onRowDragged={handleRowDrag}*/
                />}
            </>
        );
    }, [companyDetailState.companyFsps, handleAdd, handleDelete, handleEdit, handleRefresh, handleSelectionChange, memoTableBtns, memoTableTitle])

    return <><TableWrapper>{memoCompanyFspTable}</TableWrapper>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />} </>;
}

export default memo(CompanyFspTablePanel);
